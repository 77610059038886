import { fileTextIcon, paletteIcon, penRulerIcon, settingsIcon, brushIcon,calIcon, TrainingIcon ,PrioIcon} from "./menuIcons";

export const menuItems = [
  /*{
    title: 'Priority',
    id: '1',
    icon: PrioIcon,
    permission: 'access priorities',
    children: [
      {
        title: 'Zoznam priorít',
        id: '1_1',
        page: 'PriorityList',
        mask: 'prio001'
      },
      {
        title: 'Zoznam priorít malý',
        id: '1_2',
        page: 'PriorityListMobile',
        mask: 'prio002'
      },
      {
        title: 'Manuálna priorita',
        id: '1_3',
        page: 'PriorityAdd',
        permission: 'edit priorities',
        mask: 'prio003'
      },
      {
        title: 'Nastavenia',
        id: '1_4',
        permission: 'edit priorities',
        children: [
          {
            title: 'Oddelenia priorít',
            id: '1_4_1',
            page: 'PriorityDepartmentList',
            mask: 'prio004'
            //add - prio007
          },
          {
            title: 'Nast. aut. priorít',
            id: '1_4_2',
            page: 'PriorityLoadAutomaticList',
            mask: 'prio005'
          },
          {
            title: 'Nast. man. priorít',
            id: '1_4_3',
            page: 'PriorityLoadManuallyList',
            mask: 'prio006'
          },
        ],
      }
    ],
  },*/
  {
    title: 'OTK',
    id: '2',
    icon: penRulerIcon,
    permission: 'access otk-section1',
    children: [
      {
        title: 'Náhľad - OTK',
        id: '2_1',
        page: 'PreviewOTK',
        mask: 'otk001'
      },
      {
        title: 'Interná chyba',
        id: '2_2',
        page: 'ZoznamInt',
        mask: 'otk002'
        //add 03
      },
      {
        title: 'Externá chyba',
        id: '2_3',
        page: 'ZoznamExt',
        mask: 'otk004'
        //add 05
      },
      {
        title: 'Reklamácia',
        id: '2_4',
        page: 'ZoznamRek',
        mask: 'otk006'
        //add 07
      },
      {
        title: 'Dokumentácia',
        id: '2_5',
        page: 'Documentation',
        mask: 'otk008'
        //add 09
        //edit 10
      },
      {
        title: 'Merací protokol',
        id: '2_6',
        page: 'MeasurementProtocol',
        mask: 'otk011'
      },
      {
        title: 'Sebakontrola',
        id: '2_7',
        page: 'SelfControl',
        mask: 'otk012'
      },
      {
        title: 'NDT Protokol',
        id: '2_8',
        page: 'NDTProtocol',
        mask: 'otk013'
      },
      {
        title: 'Žíhací protokol',
        id: '2_9',
        page: 'AnnealingProtocol',
        mask: 'otk014'
      },
      {
        title: 'Výstup',
        id: '2_11',
        children: [
          {
            title: 'Štatistika',
            id: '2_11_1',
            page: 'OTKStatistics',
            mask: 'otk015'
          },
        ],
      },
      {
        title: 'Nastavenia',
        id: '2_10',
        permission: 'edit otk-section2',
        children: [
          {
            title: 'Druh chyby',
            id: '2_10_1',
            page: 'ErrorTypeList',
            mask: 'otk016'
            //add,edit 017
          },
          {
            title: 'Skupina',
            id: '2_10_2',
            page: 'GroupList',
            mask: 'otk018'
            //add,edit 019
          },
          {
            title: 'Zabránenie',
            id: '2_10_3',
            page: 'PreventionList',
            mask: 'otk020'
            //add,edit 021
          },
          {
            title: 'Náročnosť dielu',
            id: '2_10_4',
            page: 'MeasureComplexityList',
            mask: 'otk022'
            //add,edit 023
          },
          {
            title: 'Emaily',
            id: '2_10_5',
            permission: 'delete otk-section2',
            page: 'SummaryEmailOTKList',
            mask: 'otk024'
            //add,edit 025
          },
        ],
      }
    ],
  },
  /*{
    title: 'Lakovňa',
    id: '3',
    icon: brushIcon,
    permission: 'access painting',
    children: [
      {
        title: 'Náhľad - Lak',
        id: '3_1',
        page: 'PreviewLak',
        mask:'lak001'
      },
      {
        title: 'Podmienky lakovania',
        id: '3_2',
        page: 'PaintingConditionList',
        mask:'lak002'
      },
      {
        title: 'Nastavenia',
        id: '3_3',
        permission: 'edit painting',
        children: [
          {
            title: 'Časové podmienky',
            id: '3_3_1',
            permission: 'delete painting',
            page: 'PaintingTimeConditionList',
            mask:'lak004'
          },
          {
            title: 'Upozornenia užívateľom',
            id: '3_3_2',
            permission: 'delete painting',
            page: 'PaintingEmployeeConditionList',
            mask:'lak006'
          },
          {
            title: 'Intervaly',
            id: '3_3_3',
            page: 'PaintingIntervalList',
            mask:'lak008'
          },
          {
            title: 'Počasie',
            id: '3_3_4',
            page: 'WeatherList',
            mask:'lak010'
          },
          {
            title: 'Emaily',
            id: '3_3_5',
            permission: 'delete painting',
            page: 'SummaryEmailPaintingList',
            mask:'lak012'
          },
        ],
      },
    ],
  },*/
  /*{
    title: 'Kalibrácia',
    id: '4',
    icon: calIcon,
    permission: 'access calibration',
    children: [
      {
        title: 'Náhľad - Kal',
        id: '4_1',
        page: 'PreviewCal',
        mask:'kal001'
      },
      {
        title: 'Zoznam meradiel',
        id: '4_2',
        page: 'GaugeList',
        mask:'kal002'
      },
      {
        title: 'Zoznam kalibrácií',
        id: '4_3',
        page: 'GaugeCalibrationList',
        mask:'kal004'
      },
      {
        title: 'Kalibračné firmy',
        id: '4_4',
        page: 'CalibrationCompanyList',
        mask:'kal006'
      },
      {
        title: 'Certifikáty kal. firiem',
        id: '4_5',
        page: 'CalibrationCompanyCertificateList',
        mask:'kal008'
      },
      {
        title: 'Nastavenia',
        id: '4_5',
        permission: 'delete calibration',
        children: [
          {
            title: 'Emaily',
            id: '4_5_1',
            page: 'SummaryEmailCalibrationList',
            mask:'kal010'
          },
        ],
      },
    ],
  },*/
  /*{
    title: 'Školenie',
    icon: TrainingIcon,
    permission: 'access training',
    children: [
      {
        title: 'Záznam',
        children: [
          {
            title: 'Karta zamestnanca',
            page: 'KartaZamestnanca'
          },
          {
            title: 'Pridať nové školenie',
            page: 'NoveSkolenie'
          },
        ],
      },
      {
        title: 'Zoznam',
        children: [
          {
            title: 'Karta akt. zamestnancov',
            page: 'KartaAktZam'
          },
          {
            title: 'Karta neak. zamestnancov',
            page: 'KartaNeaktZam'
          },
          {
            title: 'Karta NOK zamestnancov',
            page: 'KartaNokZam'
          },
        ],
      },
      {
        title: 'Školiace firmy',
        children: [
          {
            title: 'Nová firma',
            page: 'NovaFirmaSkol'
          },
          {
            title: 'Zoznam firiem',
            page: 'ZoznamFiriemSkol'
          },
        ],
      },
      {
        title: 'Výstup',
        children: [
          {
            title: 'Aktívni zamestnanci',
            page: 'SkolAktExport'
          },
          {
            title: 'Neaktívni zamestnanci',
            page: 'SkolNeaktExport'
          },
          {
            title: 'NOK zamestnanci',
            page: 'SkolNokExport'
          },
          {
            title: 'Všetko',
            page: 'SkolAllExport'
          },
          {
            title: 'Karta zamestnanca',
            page: 'SkolKartaZamExport'
          },
          {
            title: 'Školenie',
            page: 'SkolExport'
          },
        ],
      },
      {
        title: 'Nastavenia',
        page: 'SkolNastavenia'
      },
    ],
  },*/
  {
    title: 'Glob. nastavenia',
    id: '5',
    icon: settingsIcon,
    permission: 'access settings',
    children: [
        {
          title: 'Oddelenie',
          id: '5_1',
          page: 'DepartmentList',
          permission: 'access users',
          mask:'glo001'
        },
        {
          title: 'Zamestnanec',
          id: '5_2',
          page: 'EmployeeList',
          permission: 'access users',
          mask:'glo003'
        },
        {
          title: 'Používatelia',
          id: '5_3',
          page: 'UserList',
          permission: 'access users',
          mask:'glo005'
        },
        {
          title: 'Dodávateľ',
          id: '5_4',
          page: 'SupplierList',
          mask:'glo007'
        },
        {
          title: 'Zákazník',
          id: '5_5',
          page: 'CustomerList',
          mask:'glo009'
        },
        {
          title: 'Používateľské role',
          id: '5_6',
          page: 'RoleList',
          permission: 'access users',
          mask:'glo011'
        },
      ],
  },
];
